import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import { LoginIdentityApplicationsGetFragmentDoc } from '../../../../core/graphql/auth/__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AuthLoginIdentityApplicationsGetQueryVariables = Types.Exact<{
  input: Types.AuthIdentityApplicationsGetInput
}>

export type AuthLoginIdentityApplicationsGetQuery = {
  __typename: 'Query'
  auth: {
    __typename: 'NamespaceAuthQuery'
    identityApplicationsGet: {
      __typename: 'AuthIdentityApplicationsGetPayload'
      companies: Array<{
        __typename: 'Company'
        name?: string | null
        logo?: {
          __typename: 'BinaryFileInfoPublic'
          publicUrl?: string | null
        } | null
      }>
      identityApplications: Array<{
        __typename: 'AuthIdentityApplication'
        identityProviderCode: Types.AuthIdentityProviderCodeEnum
        uriInit?: any | null
      }>
    }
  }
}

export const AuthLoginIdentityApplicationsGetDocument = gql`
  query AuthLoginIdentityApplicationsGet(
    $input: AuthIdentityApplicationsGetInput!
  ) {
    auth {
      identityApplicationsGet(input: $input) {
        ...LoginIdentityApplicationsGet
      }
    }
  }
  ${LoginIdentityApplicationsGetFragmentDoc}
`

/**
 * __useAuthLoginIdentityApplicationsGetQuery__
 *
 * To run a query within a React component, call `useAuthLoginIdentityApplicationsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginIdentityApplicationsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthLoginIdentityApplicationsGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthLoginIdentityApplicationsGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  > &
    (
      | {
          variables: AuthLoginIdentityApplicationsGetQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  >(AuthLoginIdentityApplicationsGetDocument, options)
}
export function useAuthLoginIdentityApplicationsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  >(AuthLoginIdentityApplicationsGetDocument, options)
}
export function useAuthLoginIdentityApplicationsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AuthLoginIdentityApplicationsGetQuery,
    AuthLoginIdentityApplicationsGetQueryVariables
  >(AuthLoginIdentityApplicationsGetDocument, options)
}
export type AuthLoginIdentityApplicationsGetQueryHookResult = ReturnType<
  typeof useAuthLoginIdentityApplicationsGetQuery
>
export type AuthLoginIdentityApplicationsGetLazyQueryHookResult = ReturnType<
  typeof useAuthLoginIdentityApplicationsGetLazyQuery
>
export type AuthLoginIdentityApplicationsGetSuspenseQueryHookResult =
  ReturnType<typeof useAuthLoginIdentityApplicationsGetSuspenseQuery>
export type AuthLoginIdentityApplicationsGetQueryResult = Apollo.QueryResult<
  AuthLoginIdentityApplicationsGetQuery,
  AuthLoginIdentityApplicationsGetQueryVariables
>
export const namedOperations = {
  Query: {
    AuthLoginIdentityApplicationsGet: 'AuthLoginIdentityApplicationsGet',
  },
}
